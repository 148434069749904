import { icons } from 'eva-icons'
import { IMenuItem } from '../../infrastructure/interfaces/sidebar'
import { Fragment } from 'react'

type MenuCardProps = {
  parent?: IMenuItem
  items?: IMenuItem[]
  selectMenu: (link: IMenuItem, parent?: IMenuItem) => void
}

const MenuCard: React.FC<MenuCardProps> = ({ parent, items, selectMenu }) => {
  const isLoading = !parent || !items

  const chunkArray = (array: any[], size: number) => {
    const result = []
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size))
    }
    return result
  }

  return (
    <div
      className={`tw-bg-white tw-bg-opacity-80 tw-p-6 tw-rounded-md full-shadow-lg tw-flex ${
        (items?.length || 0) > 6
          ? 'tw-w-[calc(50%-13px)]'
          : 'tw-w-[calc(25%-13px)]'
      } tw-flex-col`}
    >
      {isLoading ? (
        <>
          <div className="tw-w-32 tw-h-8 tw-bg-gray-300 tw-animate-pulse tw-mb-4"></div>
          <ul className="tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center">
            {[...Array(5)].map((_, index) => (
              <li
                key={`loading-${index}`}
                className="tw-w-full tw-h-6 tw-bg-gray-300 tw-animate-pulse tw-mb-2"
              ></li>
            ))}
          </ul>
        </>
      ) : (
        <>
          <div
            className={`tw-flex ${
              !parent.submenu.length &&
              'tw-cursor-pointer hover:tw-text-brandMedium '
            }`}
          >
            <span
              className="tw-fill-brand tw-flex tw-h-full tw-w-10 tw-mt-0.5"
              dangerouslySetInnerHTML={{
                __html: icons[parent.icon || 'pie-chart-outline']?.toSvg({
                  width: 24,
                  height: 24,
                }),
              }}
            />
            <ul className="tw-w-full tw-flex tw-flex-col">
              <h1
                className={`tw-text-lg tw-uppercase tw-font-bold tw-flex tw-gap-4 tw-mb-4 tw-items-center ${
                  !parent.submenu.length &&
                  'tw-cursor-pointer hover:tw-text-brandMedium'
                }`}
                onClick={() => !parent.submenu.length && selectMenu(parent)}
              >
                {parent.label}
              </h1>
                <div className="tw-flex tw-flex-row tw-gap-2 tw-w-full">
                  {chunkArray(items, 6).map((chunk, chunkIndex) => (
                    <Fragment key={`chunk-menu-${chunkIndex}`}>
                      <div
                        className={`tw-flex tw-flex-col tw-w-full tw-mr-[5px] ${
                          chunkIndex < chunkArray(items, 6).length - 1
                            ? 'tw-w-1/2 tw-border-r tw-border-dashed tw-border-gray-300'
                            : 'tw-w-1/2'
                        }`}
                      >
                        {chunk.map((item, index) => (
                          <li
                            key={`chunk-submenu-${chunkIndex}`}
                            className="tw-text-left tw-w-full tw-text-sm tw-cursor-pointer hover:tw-text-brandMedium tw-transition-colors tw-duration-300 tw-py-0.5"
                            onClick={() => selectMenu(item, parent)}
                          >
                            {item.label}
                          </li>
                        ))}
                      </div>
                      {chunkIndex < chunkArray(items, 6).length - 1 ? (
                        <div className="tw-w-[24px]" />
                      ) : null}
                    </Fragment>
                  ))}
                </div>
            </ul>
          </div>
        </>
      )}
    </div>
  )
}

export default MenuCard
